import React from "react";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "store";

import { Text } from "components/Text";
import { Select } from "components/_inputs/Select";

import { Color } from "ts/enums/color";
import { DataPreviewTable } from "./DataPreviewTable";
import { ErrorType, ImportMode } from "@explorance/mly-types";
import {
  setSelectedImportType,
  setSelectedUniqueColumn,
} from "store/analysisSettings/dataSourceSlice";
import { DataSourceModalType } from "ts/enums/dataSourceModalType";
import { Skeleton } from "components/Skeleton";
import { Icon, IconType } from "components/_icons/Icon";

type Props = {
  modalType: DataSourceModalType;
  isDemographicSelectionDisabled: boolean;
};

export const ImportSettingsStep = ({ modalType, isDemographicSelectionDisabled }: Props) => {
  const state = useAppSelector((state) => state.dataSource);
  const dispatch = useAppDispatch();
  const isLoading = !state.analysisFileSummary?.rowCount;

  const importTypeDropdownOptions = [
    {
      label: <Text resource="dataSource.dropdown.importType.[AppendAndUpdate]" />,
      value: ImportMode.AppendAndUpdate,
    },
    {
      label: <Text resource="dataSource.dropdown.importType.[ImportAlways]" />,
      value: ImportMode.ImportAlways,
    },
  ];

  const uniqueColumnDropdownOptions = state.suggestedUniqueColumns?.map((option) => ({
    label: option,
    value: option,
  }));

  const getSelectedImportTypeDropdownOption = (value: ImportMode) => {
    if (!uniqueColumnDropdownOptions)
      return importTypeDropdownOptions.find((option) => option.value === ImportMode.ImportAlways);

    const fallbackOption = importTypeDropdownOptions[0];
    if (!value) return fallbackOption;
    return importTypeDropdownOptions.find((option) => option.value === value) || fallbackOption;
  };

  const getUniqueColumnDropdownOption = (value: string) => {
    return uniqueColumnDropdownOptions.find((option) => option.value === value);
  };

  const renderHeaderSettings = () => {
    if (isLoading && modalType === DataSourceModalType.ImportFile) {
      return (
        <>
          <Skeleton width={103} height={19} />
          <Skeleton width={155} height={35} />
        </>
      );
    }
    return (
      <>
        {modalType === DataSourceModalType.ImportFile && (
          <Select
            options={importTypeDropdownOptions}
            selectedOption={getSelectedImportTypeDropdownOption(state.selectedImportType)}
            buttonStyle={{ width: "175px" }}
            dropdownWidth="175px"
            selectLabelKey="modal.import.stepTwo.type"
            dropdownPosition={{ top: 35, right: 0 }}
            handleChange={(option) => dispatch(setSelectedImportType(option))}
            disabled={!uniqueColumnDropdownOptions || uniqueColumnDropdownOptions?.length === 0}
          />
        )}
        {uniqueColumnDropdownOptions?.length > 0 && (
          <Select
            options={uniqueColumnDropdownOptions}
            selectedOption={getUniqueColumnDropdownOption(state.selectedUniqueColumn)}
            buttonStyle={{ width: "155px" }}
            dropdownWidth="155px"
            dropdownPosition={{ top: 35, right: 0 }}
            selectLabelKey="modal.import.stepTwo.uniqueColumn"
            handleChange={(option) => dispatch(setSelectedUniqueColumn(option))}
            disabled={state.selectedImportType === ImportMode.ImportAlways}
          />
        )}
      </>
    );
  };

  const headerTitleResourceKey = {
    [DataSourceModalType.ImportFile]: "modal.import.stepTwo.title",
    [DataSourceModalType.ViewData]: "modal.import.editDataTypes.title",
  }[modalType];

  return (
    <>
      <StyledHeader>
        <StyledHeaderTextSection>
          <b>
            <Text resource={headerTitleResourceKey} />
          </b>

          <StyledHeaderDescription>
            {isLoading ? (
              <Skeleton width={460} height={18} />
            ) : (
              <>
                <Text resource="modal.import.stepTwo.description" />
                <b>
                  <Text
                    resource={{
                      key: "dataSource.totalCount",
                      args: [`${state.analysisFileSummary?.rowCount}`],
                    }}
                  />
                </b>
              </>
            )}
          </StyledHeaderDescription>
        </StyledHeaderTextSection>
        <StyledHeaderSettings>{renderHeaderSettings()}</StyledHeaderSettings>
      </StyledHeader>
      <StyledProgressBar>
        <StyledCurrentProgress />
      </StyledProgressBar>
      <StyledDataPreviewTableContainer>
        <DataPreviewTable isDemographicSelectionDisabled={isDemographicSelectionDisabled} />
      </StyledDataPreviewTableContainer>
      {state.error?.type === ErrorType.DemographicInvalidDateFormat && (
        <StyledInvalidDateFormatError>
          <StyledInvalidDateFormatErrorTitle>
            <Icon type={IconType.warning} size={12} color={Color.red50} />
            <Text resource="dataSource.importValidationError" />
          </StyledInvalidDateFormatErrorTitle>
          <Text
            resource={{
              key: "dataSource.importValidationError.description",
              args: [
                state.error.details.column,
                state.error.details.rowNum?.toString(),
                state.error.details.selectedDateFormat,
              ],
            }}
          />
        </StyledInvalidDateFormatError>
      )}
    </>
  );
};

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const StyledHeaderTextSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  padding-right: 20px;
`;

const StyledHeaderSettings = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledProgressBar = styled.div`
  width: calc(100% + 48px);
  height: 5px;
  margin-left: -24px;
  background-color: ${Color.blue20};
  margin-bottom: 12px;
`;

const StyledCurrentProgress = styled.div`
  width: 50%;
  height: 100%;
  background-color: ${Color.green50};
`;

const StyledDataPreviewTableContainer = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
`;

const StyledHeaderDescription = styled.div`
  margin-top: 5px;
  font-size: 14px;
  display: flex;
  b {
    padding-left: 4px;
  }

  @media (max-width: 1366px) {
    display: block;
    b {
      padding-left: 0;
    }
  }
`;

const StyledInvalidDateFormatError = styled.div`
  padding-top: 16px;
  font-size: 14px;
  color: ${Color.red50};
`;

const StyledInvalidDateFormatErrorTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;
  margin-bottom: 8px;
`;
