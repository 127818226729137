import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import CreatableSelect from "react-select/creatable";

import { getCategoryStyles, getTagStyles } from "./helpers";
import { useResource } from "hooks/useResource";
import { useAppDispatch, useAppSelector } from "store";
import { CreatableSelectOption, setTags, setSelectedCategory } from "store/pin/pinSlice";

import { Button } from "components/_buttons/Button";
import { Popup } from "components/Popup";
import { Text } from "components/Text";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { PopupPosition } from "ts/enums/popupPosition";
import { Color } from "ts/enums/color";
import { fetchCategories, fetchTags } from "store/pin/thunk";
import { Pin } from "ts/pin";
import { createPinForComments, unpinComment, updatePin } from "services/comments";
import { showToastError, showToastSuccess } from "store/toast/toastSlice";
import { AnalysisContext } from "context/AnalysisContext";
import { setSelectedCategories } from "context/AnalysisContext/actions";

type Props = {
  anchorEl: HTMLElement;
  bulkCommentIds?: number[];
  setIsPopupOpen: (boolean) => void;
  updateCommentPin: (ids: number[], pinData?: Pin) => void;
};

const TAG_INPUT_MAX_LENGTH = 100;

export const PinPopup = ({ anchorEl, setIsPopupOpen, bulkCommentIds, updateCommentPin }: Props) => {
  const [analysisContextState, analysisContextDispatch] = useContext(AnalysisContext);
  const state = useAppSelector((state) => state.commentPin);
  const dispatch = useAppDispatch();

  const [isCategoryInputActive, setIsCategoryInputActive] = useState<boolean>(false);
  const [isTagInputActive, setIsTagInputActive] = useState<boolean>(false);

  const commentIds = bulkCommentIds ? bulkCommentIds : [state.comment.id];

  const { getResource } = useResource();

  const pinCommentHandler = async () => {
    const pinArgs = {
      ids: commentIds,
      category: state.selectedCategory.value,
      tags: state.tags.map((tag) => tag.value),
    };
    try {
      const action = state.comment?.pin ? updatePin : createPinForComments;
      await action(pinArgs);
      dispatch(showToastSuccess("toast.pinSuccessful"));
    } catch {
      dispatch(showToastError("toast.defaultError"));
    } finally {
      // adds pin data to the comment state in the comments file
      updateCommentPin(commentIds, {
        category: state.selectedCategory.value,
        tags: state.tags.map((tag) => tag.value),
      });
      analysisContextState.selectedCategories.length > 0 &&
        analysisContextDispatch(setSelectedCategories([]));
      setIsPopupOpen(false);
    }
  };

  const unpinHandler = async () => {
    try {
      await unpinComment(commentIds);
      dispatch(showToastSuccess("toast.unpinSuccessful"));
    } catch {
      dispatch(showToastError("toast.defaultError"));
    } finally {
      updateCommentPin(commentIds);
      setIsPopupOpen(false);
    }
  };

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchTags());
  }, []); // eslint-disable-line

  return (
    <Popup
      isOpen
      anchorEl={anchorEl}
      placement={bulkCommentIds ? PopupPosition.bottomStart : PopupPosition.bottomEnd}
      popupStyles={{ width: 460, maxHeight: 400, padding: 10 }}
      fixedPopup
    >
      <StyledContentContainer>
        <StyledCommentContainer>
          <StyledHeader removeMargin={!!bulkCommentIds}>
            {bulkCommentIds ? (
              <Text
                resource={{
                  key:
                    bulkCommentIds.length > 1
                      ? "comments.pinComment.popup.bulk.title"
                      : "comments.pinComment.popup.bulk.title.singular",
                  args: [`${bulkCommentIds.length}`],
                }}
              />
            ) : (
              <Text resource="comments.pinComment.popup.header" />
            )}
          </StyledHeader>
          <StyledComment>{!bulkCommentIds && state.comment.value}</StyledComment>
        </StyledCommentContainer>
        <div>
          <StyledLabel>
            <Text resource="comments.pinComment.popup.category" />
          </StyledLabel>
          <CreatableSelect
            options={state.categories.map((cat) => ({ label: cat.name, value: cat.name }))}
            styles={getCategoryStyles()}
            value={state.selectedCategory}
            onChange={(newValue) =>
              dispatch(setSelectedCategory(newValue as CreatableSelectOption))
            }
            placeholder={getResource("comments.pinComment.popup.categorySelect.placeholder")}
            onFocus={() => setIsCategoryInputActive(true)}
            onBlur={() => setTimeout(() => setIsCategoryInputActive(false), 150)}
            blurInputOnSelect
            isSearchable
          />
        </div>
        <div>
          <StyledLabel>
            <Text resource="comments.pinComment.popup.tags" />
          </StyledLabel>
          <CreatableSelect
            options={state.tagOptions.map((tag) => ({ label: tag.name, value: tag.name }))}
            styles={getTagStyles()}
            components={{
              DropdownIndicator: null,
            }}
            value={state.tags}
            onChange={(newValue) => dispatch(setTags(newValue as CreatableSelectOption[]))}
            onInputChange={(inputValue) =>
              inputValue.length <= TAG_INPUT_MAX_LENGTH
                ? inputValue
                : inputValue.slice(0, TAG_INPUT_MAX_LENGTH)
            }
            onFocus={() => setIsTagInputActive(true)}
            onBlur={() => setTimeout(() => setIsTagInputActive(false), 150)}
            placeholder={getResource("comments.pinComment.popup.input.placeholder")}
            menuPlacement="top"
            blurInputOnSelect
            isSearchable
            isMulti
          />
        </div>
        <StyledActionSection>
          {!bulkCommentIds && (
            <Button
              variant={ButtonVariant.secondary}
              size={ButtonSize.sm}
              onClick={unpinHandler}
              disabled={!state.comment?.pin}
            >
              <Text resource="comments.pinComment.popup.button.unpinComment" />
            </Button>
          )}
          <div>
            <Button
              variant={ButtonVariant.light}
              size={ButtonSize.ml}
              onClick={() => setIsPopupOpen(false)}
            >
              <Text resource="button.cancel" />
            </Button>
            <Button
              variant={ButtonVariant.primary}
              size={ButtonSize.ml}
              disabled={!state.selectedCategory || isCategoryInputActive || isTagInputActive}
              onClick={pinCommentHandler}
            >
              <Text resource="comments.pinComment.popup.button.pinComment" />
            </Button>
          </div>
        </StyledActionSection>
      </StyledContentContainer>
    </Popup>
  );
};

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledHeader = styled.div<{ removeMargin: boolean }>`
  font-size: 12px;
  color: ${Color.gray30};
  font-weight: bold;
  margin-bottom: ${({ removeMargin }) => !removeMargin && "5px"};
`;

const StyledCommentContainer = styled.div`
  padding: 12px;
  background-color: ${Color.neutral10};
`;

const StyledComment = styled.div`
  color: ${Color.gray50};
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 50px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const StyledLabel = styled.div`
  font-size: 12px;
  color: ${Color.gray30};
  font-weight: bold;
  margin-bottom: 8px;
`;

const StyledActionSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding: 18px 0;

  div {
    display: flex;
    gap: 12px;
    margin-left: auto;
  }
`;
